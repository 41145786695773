@media only screen and (min-width: 768px) {
  .h-unset {
    height: unset;
  }
}

button:disabled {
  opacity: 0.7;
}

.text-danger {
  color: #ff4848;
}

.img-full {
  width: 100%;
  height: 100%;
  object-fit: cover;
}