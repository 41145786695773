.Select {
  outline: 0;
  padding: 10px;
  border-color: transparent;
  border-bottom: 1px solid white;
  font-size: 15px;
  background: transparent;
  color: #fcfcfc;
}

.Select:focus {
  outline: none;
  border-color: rgb(0 154 238);
}

.Select.invalid-input {
  border-color: #ff4848;
}

.invalid-feedback {
  display: block;
  margin-top: 0.5rem;
  color: #ff4848;
}

.Select option[data-default] {
  display: none;
}