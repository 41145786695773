.hero {
  background-image: url("https://i.imgur.com/rXQYISH.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.navbar .logo {
  max-width: 182px;
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .hero {
    background-image: url("https://i.imgur.com/k6DP62F.png");
  }
}
