.btn-burger {
  width: 40px;
  height: 20px;
  transition: all ease 0.5s;
}

.cont-main {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
  z-index: 6;
}
.transition-entrance {
  transform: translate(768px);
}
.cont-main .list {
  list-style: none;
  padding: 0;
}
.cont-main .item-list a {
  display: block;
  text-decoration: none;
}

@media (min-width: 768px) {
  .btn-burguer-container {
    display: none;
  }
  .cont-main {
    display: none;
  }
}
