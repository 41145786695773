.Input {
  outline: 0;
  padding: 10px;
  border-color: transparent;
  border-bottom: 1px solid white;
  font-size: 15px;
  background: transparent;
  color: #ffffff;
}

.Input:focus {
  outline: none;
  border-color: rgb(0 154 238);
}

.Input::-webkit-input-placeholder {
  padding-left: 5px;
  font-size: 15px;
  color: #416b98;
}

.Input:-moz-placeholder {
  /* Firefox 18- */
  padding-left: 5px;
  font-size: 15px;
  color: #416b98;
}

.Input::-moz-placeholder {
  /* Firefox 19+ */
  padding-left: 5px;
  font-size: 15px;
  color: #416b98;
}

.Input:-ms-input-placeholder {
  padding-left: 5px;
  font-size: 15px;
  color: #416b98;
}

.Input.invalid-input {
  border-color: #ff4848;
}

.invalid-feedback {
  display: block;
  margin-top: 0.5rem;
  color: #ff4848;
}
