.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 56px;
  justify-content: center;
  max-width: 1070px;
}

.card {
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 34px 26px;
  width: 338px;
  height: 410px;
  background: #fcfcfc;
  box-shadow: 0px 0px 25px rgba(18, 47, 73, 0.1);
  border-radius: 20px;
  margin: auto;
}

/* Media query para cambiar a 2x3 */
@media (max-width: 1280px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    max-width: 732px;
  }
}

/* Media query para cambiar a una columna */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .card {
    height: 400px;
  }
}
