.our-story-container {
  background-image: url("https://i.imgur.com/UDajyKR.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

@media only screen and (max-width: 768px) {
  .our-story-container {
    background-image: url("https://i.imgur.com/SxhNqo8.jpg");
  }
}
