@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: scandia-web, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 15px;
  color: #122f49;
  max-width: 1800px;
  margin: auto;
  background-color: #111a22;
}

* {
  box-sizing: border-box;
}

h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  text-align: center;
}

.secondary-text {
  text-align: center;
  font-size: 24px;
  margin-bottom: 70px;
}

@media only screen and (max-width: 992px) {
  h2 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 36px;
  }
  .secondary-text {
    font-size: 18px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 576px) {
  h2 {
    font-size: 30px;
  }
}
