.comment {
  max-width: 500px;
  width: 100%;
  background-color: #fcfcfc;
  padding: 40px;
  box-shadow: -3px 6px 16px rgba(18, 47, 73, 0.1);
  border-radius: 5px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .comment {
    max-width: 400px;
    width: 100%;
  }
}
