.contact-container {
  box-shadow: 0px 0px 25px rgba(18, 47, 73, 0.5);
}

.contact-image-filter::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    1.04deg,
    rgba(18, 47, 73, 0.8) 0.42%,
    rgba(46, 79, 108, 0.8) 98.65%
  );
}
